export const query = `query getCheckoutSettings {
  checkoutSettings {
    logo {
      url
      mediaType
      title
      width
      height
    }
    storeName
  }
}`;
