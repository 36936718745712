import {StoreCheckoutSettings} from '@wix/wixstores-graphql-schema-node';

export class PaymentRequestSettingsModel {
  public storeName?: string;
  public logoUrl?: string;

  constructor(paymentRequestSettings?: StoreCheckoutSettings) {
    this.storeName = paymentRequestSettings?.storeName ?? undefined;
    this.logoUrl = paymentRequestSettings?.logo?.url;
  }
}
